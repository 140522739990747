<template>
  <div class="manage">

    <!--    搜索区域开始   -->
    <el-collapse>
      <el-collapse-item title="搜索" name="1">
        <el-form :model="searchForm"
                 :inline="true"
                 label-width="80px">
        <el-form-item label="客户端ip地址" prop="ClientAddress">
          <el-input placeholder="请输入客户端ip地址" v-model="searchForm.ClientAddress"/>
        </el-form-item>
        <el-form-item label="访问的地址" prop="Path">
          <el-input placeholder="请输入访问的地址" v-model="searchForm.Path"/>
        </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <!--    搜索区域结束   -->

    <!--    分割线-->
    <el-divider></el-divider>

    <!-- 弹窗区域开始 -->
    <el-dialog
      :title="this.scene === 1 ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="50%"
      :before-close="cancelDialog">
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :inline="false"
        :model="form"
        label-width="80px"
        :rules="rules">

      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog">取 消</el-button>
          <el-button type="primary" @click="submitClick">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 弹窗区域结束 -->

    <!-- 表头按钮区域开始 -->
    <div class="manage-header">
      <el-button size="mini" class="el-icon-search" type="success" @click="searchClick" plain>搜索</el-button>
    </div>
    <!-- 表头按钮区域结束 -->

    <!-- 表格 -->
    <el-table
       :data="pageData.List"
       style="width: 100%;margin-bottom: 20px;"
       row-key="id"
       border
       v-loading="tableLoad">
      <el-table-column prop="Id" label="Id"/>
      <el-table-column prop="HostAddress" label="主机地址"/>
      <el-table-column prop="ClientAddress" label="客户端ip地址"/>
      <el-table-column prop="Path" label="访问的地址"/>
      <el-table-column prop="Scene" label="访问的场景"/>
      <el-table-column prop="CreateTime" label="创建时间"/>
      <el-table-column label="操作">
        <template  v-slot="{row}">
         <div>
           <el-button size="mini" @click="deleteClick(row)" type="danger">删除</el-button>
         </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div>
      <el-pagination
        layout="prev, pager, next"
        :total="pageData.Total"
        @current-change="pagingClick" />
    </div>
  </div>
</template>

<script>
import '@/assets/static/css/base.css'
import service from '@/service/System/LogService'
export default service
</script>
